<template>
  <div class="">
    <b-card class="mt-1">
      <div class="row">
        <div class="col-12">
          <h4 class="d-inline-block"><i class="fa fa-fill"></i> <span v-show="editBucket">Edit </span>Cost Code Bucket Templates</h4>

          <router-link v-if="$session.get('user') == 'pm'" v-show="!editBucket" :to="{ name: 'Project Manager Create Cost Code Buckets' }" class="btn btn-success float-right">
            Create New Bucket <i class="fa fa-plus"></i>
          </router-link>
          <router-link v-if="$session.get('user') == 'manager'" v-show="!editBucket" :to="{ name: 'Manager Create Cost Code Buckets' }" class="btn btn-success float-right">
            Create New Bucket <i class="fa fa-plus"></i>
          </router-link>
          <router-link v-if="$session.get('user') == 'accountant'" v-show="!editBucket" :to="{ name: 'Accountant Create Cost Code Buckets' }" class="btn btn-success float-right">
            Create New Bucket <i class="fa fa-plus"></i>
          </router-link>
          <hr v-show="!editBucket">
        </div>
        <div class="col-md-6 col-xl-6 mt-2" v-show="editBucket">
          <h4>Bucket Name</h4>
          <input type="text" name="bucket" v-model="bucketName" class="form-control" placeholder="Bucket Name">
        </div>
        <div class="col-md-6 col-xl-6 mt-2" v-show="editBucket">
          <h5>&nbsp;</h5>
          <button type="button" v-show="editBucket" @click="saveBucket()" class="btn btn-info mt-1" style="color:white;" name="button">
            Save Bucket <i class="fa fa-save"></i>
          </button>
        </div>
        <div class="col-md-6 col-xl-6" v-if="buckets.length > 0" v-show="!editBucket">
          <h5>Select Bucket Template</h5>
          <multiselect v-model="selectedBucket" @input="getBucketCost(), showCollapse = true" placeholder="Search or add bucket" tag-placeholder="Search or add project" track-by="cc_bucketid" :options="buckets" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="false" label="name" :preselect-first="false">
          </multiselect>
        </div>
        <div class="col-md-6 col-xl-6" v-if="!lodash.isEmpty(selectedBucket)">
          <h5>&nbsp;</h5>
          <button type="button" v-show="!editBucket" @click="editTheBucket()" class="btn btn-primary lineup-button" name="button">
            Edit Bucket <i class="fa fa-edit"></i>
          </button>
          <button type="button" v-show="!editBucket" @click="removeTheBucket()" class="btn btn-danger lineup-button ml-2" name="button">
            Delete Bucket <i class="fa fa-minus-circle"></i>
          </button>
        </div>
      </div>
    </b-card>
    <b-card v-if="buckets.length > 0 && !lodash.isEmpty(selectedBucket)">
      <b-button v-b-toggle.collapse variant="primary">
        <span class="when-opened">Close Chart <i class="fa fa-chevron-up"></i></span> <span class="when-closed">Open Bar Chart <i class="fa fa-chart-bar"></i></span>
      </b-button>
      <b-collapse id="collapse" class="mt-2" v-model="showCollapse">
        <div id="bucketChart" class="mt-3">
          <div id="chartdiv" style="height: 300px;"></div>
        </div>
      </b-collapse>
    </b-card>
    <b-card>
      <div class="row">
        <div class="col-md-12 mt-3">
          <v-client-table v-show="editBucket" class="bucket-table" stacked :columns="allBucketColumns" :data="allBuckets" :options="allBucketOptions" :theme="theme" id="chain-data">
            <div slot="delete" slot-scope="props">
              <button type="button" @click="removeFromAllBucket(props.row)" class="btn btn-danger btn-sm" name="button">
                <i class="fa fa-minus-circle"></i>
              </button>
            </div>
            <template slot="child_row" class="first-child-toggler" slot-scope='props'>
              <v-client-table class="rt-table-striped chain-data-child-table" :data="props.row.cc_group" :columns="childAllBucketColumnsAssignedServices" :options="childAllBucketOptionsAssignedServices" :theme="theme">
                <div slot="cc3" slot-scope="props">
                  <span v-if="!props.row.cc3.includes('---')">{{props.row.cc3}}</span>
                </div>
              </v-client-table>
            </template>
          </v-client-table>
        </div>
        <div class="col-md-12 mt-3" v-show="!editBucket">
          <h4 class="mb-4" v-if="bucket.cc_group">Total: ${{formatPrice(totalCost)}} VS Budget: ${{formatPrice(totalBudget)}} = <span v-bind:class="{ danger: totalCost > totalBudget, green: totalCost < totalBudget }">${{formatPrice(totalCost - totalBudget)}}</span></h4>
          <table class="table bucket-table">
            <thead>
              <th>AFE / PO</th>
              <th>Cost Code 1 / Major</th>
              <th>Cost Code 2 / Minor</th>
              <th>Cost Code 3 / Description</th>
              <th>Cost</th>
              <th>Budget</th>
              <th>Bucket</th>
              <th>Total Cost</th>
              <th>Total Budget</th>
            </thead>
            <rotate-square2 id="loading-gif" v-show="loading"></rotate-square2>
            <tbody style="border-top: 8px solid #c8ced3;" v-for="group in bucket.cc_group">
              <tr v-for="(row, index) in group.cc_group">
                <td>{{row.afe}}</td>
                <td>{{row.cc1}}</td>
                <td>{{row.cc2}}</td>
                <td><span v-if="!row.cc3.includes('---')">{{row.cc3}}</span></td>
                <td>${{formatPrice(row.total.total)}}</td>
                <td>${{formatPrice(row.budget)}}</td>
                <td v-if="index == 0" :rowspan="group.cc_group.length">{{group.name}}</td>
                <td v-if="index == 0" :rowspan="group.cc_group.length">${{formatPrice(group.totalCost)}}</td>
                <td v-if="index == 0" :rowspan="group.cc_group.length">${{formatPrice(group.totalBudget)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-xl-6 mt-2" v-show="editBucket">
          <h5>New Bucket Name</h5>
          <input type="text" name="bucket" v-model="newBucketName" class="form-control" placeholder="Bucket Name">
        </div>
        <div class="col-md-6 col-xl-6 mt-2" v-show="editBucket">
          <h5>&nbsp;</h5>
          <button type="button" @click="addBucketToBuckets()" class="btn btn-success" name="button">
            Add Bucket To Template <i class="fa fa-plus"></i>
          </button>
        </div>
        <div class="col-md-12 mt-3">
          <v-client-table v-if="newBucket.length > 0" class="chain-table" stacked :columns="bucketColumns" :data="newBucket" :options="bucketOptions" :theme="theme" id="chain-data">
            <div slot="delete" slot-scope="props">
              <button type="button" @click="removeFromBucket(props.row)" class="btn btn-danger btn-sm" name="button">
                <i class="fa fa-minus-circle"></i>
              </button>
            </div>
            <div slot="cc3" slot-scope="props">
              <span v-if="!props.row.cc3.includes('---')">{{props.row.cc3}}</span>
            </div>
          </v-client-table>
        </div>
      </div>
    </b-card>
    <b-card class="" v-show="editBucket">
      <div class="row">
        <div class="col-md-6 col-xl-6">
          <h5>Select Projects <i class="ml-2 fa fa fa-question-circle" v-b-tooltip.hover title="You may select multiple projects"></i></h5>
          <multiselect @input="get_afes()" v-model="projectids" placeholder="Search or add project" tag-placeholder="Search or add project" track-by="projectid" :options="projectData" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="true" label="projectname" :preselect-first="true">
          </multiselect>
        </div>
        <div class="col-md-6 col-xl-6" v-if="afeData.length > 0">
          <h5>Select AFE / PO</h5>
          <multiselect v-model="afes" @input="get_chains()" placeholder="Search or add afe" tag-placeholder="Search or add afe" track-by="pwcaid" :options="afeData" :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="true" label="afenum" :preselect-first="false">
          </multiselect>
        </div>
      </div>

      <v-client-table v-if="groupChainData.length > 0" v-show="editBucket" class="chain-table mt-4" stacked :columns="columnsAssignedServices" :data="groupChainData" :options="optionsAssignedServices" :theme="theme" id="chain-data">
        <template slot="child_row" class="first-child-toggler" slot-scope='props'>
          <v-client-table class="rt-table-striped chain-data-child-table" :data="props.row.children" :columns="childColumnsAssignedServices" :options="childOptionsAssignedServices" :theme="theme">
            <div slot="name" slot-scope="props">
              {{props.row.name}}
              <button type="button" @click="addToBucket(props.row)" name="button">
                Add To Bucket <i class="fa fa-plus"></i>
              </button>
            </div>
            <template slot="child_row" slot-scope='props'>
              <v-client-table class="rt-table-striped chain-data-child-table" :data="props.row.children.filter(x => !x.name.includes('---'))" :columns="childColumnsAssignedServices" :options="childOptionsAssignedServices" :theme="theme">
                <div slot="name" slot-scope="props">
                  <div v-if='props.row.name.includes("---")'>
                  </div>
                  <div v-else>
                    {{props.row.name}}
                    <button type="button" @click="addToBucket(props.row)" name="button">
                      Add To Bucket <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </v-client-table>
            </template>
          </v-client-table>
        </template>
      </v-client-table>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import {ClientTable, Event} from 'vue-tables-2'
Vue.use(ClientTable)
import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import am4themes_material from "@amcharts/amcharts4/themes/material";

am4core.useTheme(am4themes_animated);
//am4core.useTheme(am4themes_material);

export default {
  name: "CostCodeBuckets",
  components: {
    Multiselect,
    ClientTable,
    RotateSquare2,
  },
  data : function () {
    return {
      showCollapse: false,
      allBuckets: [],
      //Services Data Table
      allBucketColumns: ['name', 'delete'],
      allBucketOptions: {
        headings: {
          delete: 'Delete',
          name: 'Bucket Name',
        },
        sortable: ['cc1', 'cc2', 'cc3', 'afenum'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 10
      },
      childAllBucketColumnsAssignedServices: ['afe', 'cc1', 'cc2', 'cc3'],
      childAllBucketOptionsAssignedServices: {
        headings: {
          afe: 'AFE / PO',
          cc1: 'Cost Code 1 / Major',
          cc2: 'Cost Code 2 / Minor',
          cc3: 'Cost Code 3 / Description',
        },
        sortable: ['serviceCompanyName'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 50
      },
      projectData: [],
      afeData: [],
      projectids: [],
      afes: [],
      chainData: [],
      bucketName: "",
      selectedBucket: "",
      newBucketName: "",
      budgets: [],
      theme: 'bootstrap4',
      template: 'default',
      bucket: [],
      buckets: [],
      newBucket: [],
      //Services Data Table
      bucketColumns: ['delete', 'afe', 'cc1', 'cc2', 'cc3', 'total'],
      bucketOptions: {
        headings: {
          delete: '',
          afe: 'AFE / PO',
          cc1: 'Cost Code 1 / Major',
          cc2: 'Cost Code 2 / Minor',
          cc3: 'Cost Code 3 / Description',
          total: 'Cost',
        },
        sortable: ['cc1', 'cc2', 'cc3', 'afenum'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 10
      },
      optionsAssignedServices: {
        headings: {
          cc1: 'Cost Code 1 / Major',
          cc2: 'Cost Code 2 / Minor',
          cc3: 'Cost Code 3 / Description',
        },
        sortable: ['serviceCompanyName'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 50
      },
      columnsAssignedServices: ['name'],
      optionsAssignedServices: {
        headings: {
          name: 'Cost Codes',
        },
        sortable: ['serviceCompanyName'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 50
      },
      childColumnsAssignedServices: ['name'],
      childOptionsAssignedServices: {
        headings: {
          name: 'Cost Code',
        },
        sortable: ['status', 'ccone_code', 'cctwo_code', 'ccthree_code', 'serviceName', 'serviceCompanyName'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 50
      },
      lastChildColumnsAssignedServices: ['serviceName', 'total', 'delete'],
      lastChildOptionsAssignedServices: {
        headings: {
          serviceName: 'Service Name',
          total: 'Total',
          delete: 'Delete',
        },
        sortable: ['code', 'name'],
        filterable: false,
        sortIcon: { base:'fa', up:'fa-sort-up', down:'fa-sort-down', is:'fa-sort' },
        perPage: 50
      },
      editBucket: false,
      loading: false,
      companyid: 0
    }
  },
  computed: {
    totalCost: function(){
      if(this.bucket.cc_group){
        return this.bucket.cc_group.reduce(function(total, item){
          return total + item.totalCost
        },0);
      }
    },
    totalBudget: function(){
      if(this.bucket.cc_group){
        return this.bucket.cc_group.reduce(function(total, item){
          return total + item.totalBudget
        },0);
      }
    },
    groupChainData: function() {
      // Get all the required ids
      var afes = this.afes.map(x=>x.pwcaid)

      var filteredByAfenums = this.chainData.filter(function(a) {
        // Check if both source and target are present in list of ids
        return afes.indexOf(a.pwcaid) !== -1 && afes.indexOf(a.pwcaid) !== -1;
      });

      var chainData = this.lodash.chain(filteredByAfenums)
      .groupBy('ccone_code')
      .map((code, name) => ({name, code }))
      .value();

      for (var i = 0; i < chainData.length; i++) {
        chainData[i].id = i;

        chainData[i].children = this.lodash.chain(chainData[i].code)
        .groupBy('cctwo_code')
        .map((code, name) => ({name, code }))
        .value();

        for (var x = 0; x < chainData[i].children.length; x++) {
          chainData[i].children[x].id = x;
          chainData[i].children[x].children = this.lodash.chain(chainData[i].children[x].code)
          .groupBy('ccthree_code')
          .map((code, name) => ({name, code }))
          .value();
        }
      }

      return chainData;
    }
  },
  methods: {
    removeFromAllBucket(row){
      var find = this.allBuckets.findIndex(x=>x.name == row.name)
      Vue.delete(this.allBuckets, find)
    },
    async getCost(row) {
      return this.$http.get('/cc_bucket/get/cost/' + encodeURIComponent(row.cc1) + '/' + encodeURIComponent(row.cc2) + '/' + encodeURIComponent(row.cc3) + '/' + encodeURIComponent(row.pwcaid))
      .then(response => {
        return response.data.result;
      })
      .catch(error => {
        console.log(error)
      })
    },
    async getBucketCost() {
      this.loading = true;
      this.selectedBucket.cc_group = JSON.parse(this.selectedBucket.cc_group);
      for (var i = 0; i < this.selectedBucket.cc_group.length; i++) {
      var totalBudget = 0;
      var totalCost = 0;
      for (var y = 0; y < this.selectedBucket.cc_group[i].cc_group.length; y++) {
        //this.selectedBucket.cc_group[i].cc_group[y].total = 30.00
        const cost = await this.getCost(this.selectedBucket.cc_group[i].cc_group[y])
        this.selectedBucket.cc_group[i].cc_group[y].total = cost

        totalCost += cost.total;

        var filtered = this.budgets.filter(x=>x.cc1_id == this.selectedBucket.cc_group[i].cc_group[y].cc1id && x.cc2_id == this.selectedBucket.cc_group[i].cc_group[y].cc2id && x.cc3_id == this.selectedBucket.cc_group[i].cc_group[y].cc3id)
        if(filtered.length > 0){
          this.selectedBucket.cc_group[i].cc_group[y].budget = filtered[0].budget;
          totalBudget += filtered[0].budget;
        }
        else {
          this.selectedBucket.cc_group[i].cc_group[y].budget = 0;
        }
      }

      this.selectedBucket.cc_group[i].totalBudget = totalBudget;
      this.selectedBucket.cc_group[i].totalCost = totalCost;
    }

      this.bucket = this.selectedBucket;
      this.loadChart(this.bucket);
      this.loading = false;
    },
    editTheBucket() {
      this.editBucket = true;
      this.bucketName = this.bucket.name;
      this.project_ids = this.bucket.projectids;

      this.bucket.cc_group.forEach(line => {
        var cc_group = [];
        line.cc_group.forEach(group => {
          cc_group.push({
            "cc1": group.cc1,
        		"cc2": group.cc2,
        		"cc3": group.cc3,
            "afe": group.afe,
            "pwcaid":group.pwcaid,
            "cc1id": group.cc1id,
            "cc2id": group.cc2id,
            "cc3id": group.cc3id,
        		"service_ccid": group.service_ccid
          })
        })
        this.allBuckets.push({
          "id": line.cc_group.length,
          "cc_group": cc_group,
          "name": line.name,
        })
      })


    },
    removeTheBucket() {
      let data = JSON.stringify({
        "cc_bucketid": this.selectedBucket.cc_bucketid,
        "cc_group": this.selectedBucket.cc_group,
      	"name": this.selectedBucket.name,
      	"status": 0,
      	"company_id": this.selectedBucket.company_id,
      	"project_id": this.selectedBucket.project_id
      })

      this.$http.post('/cc_bucket/update/', data)
      .then((response) => {
        console.log(response)
        this.selectedBucket = [];
        this.$toasted.show('Successfully Deleted Cost Code Bucket.', {type: 'success', duration: '3000'})
        this.getBuckets();
      })
    },
    getBuckets(){
      this.$http.get('/cc_bucket/get/')
      .then((response) => {
        this.buckets = response.data.result.filter(x=>x.status == 1);
      })
      .catch((error) => {
        console.log(error)
      })
    },
    addBucketToBuckets(){
      if(this.newBucketName == "" || this.newBucket.length == 0){
        this.$toasted.show('Bucket is not complete', {type: 'error', duration: '3000'})
        return
      }

      var cc_group = [];
      this.newBucket.forEach(line => {
        cc_group.push({
          "cc1": line.cc1,
      		"cc2": line.cc2,
      		"cc3": line.cc3,
          "afe": line.afe,
          "pwcaid":line.pwcaid,
          "cc1id": line.cc1id,
          "cc2id": line.cc2id,
          "cc3id": line.cc3id,
      		"service_ccid": line.service_ccid
        })
      })

      this.allBuckets.push({
        "id": this.allBuckets.length + 1,
        "cc_group": cc_group,
        "name": this.newBucketName,
      })

      this.newBucketName = [];
      this.newBucket = [];
    },
    addToBucket(row){
      this.newBucket.push({
        "cc1": row.code[0].ccone_code,
        "cc1": row.code[0].ccone_code,
        "cc2": row.code[0].cctwo_code,
        "cc3": row.code[0].ccthree_code,
        "cc1id": row.code[0].cconeid,
        "cc2id": row.code[0].cctwoid,
        "cc3id": row.code[0].ccthreeid,
        "afe": row.code[0].afenum,
        "pwcaid":row.code[0].pwcaid,
        "service_ccid": row.code[0].service_ccid,
      })
    },
    removeFromBucket(row){
      var find = this.bucket.cc_group.findIndex(x=>x.cc1 == row.cc1 && x.cc2 == row.cc2 && x.cc3 == row.cc3 && x.afe == row.afe && x.service_ccid == row.service_ccid)
      Vue.delete(this.bucket.cc_group, find)
    },
    saveBucket(){
      if(this.allBuckets.length == 0 || this.selectedBucket.name == ""){
        this.$toasted.show('Bucket is empty', {type: 'error', duration: '3000'})
        return
      }
      var cc_group = [];

      let data = JSON.stringify({
        "cc_bucketid": this.bucket.cc_bucketid,
        "cc_group": this.allBuckets,
      	"name": this.bucketName,
      	"status": 1,
      	"company_id": this.companyid,
      	"project_id": [0]
      })

      console.log(data)

      this.$http.post('/cc_bucket/update/', data)
      .then((response) => {
        console.log(response)
        this.editBucket = false;
        this.allBuckets = [];
        this.bucket = [];
        this.selectedBucket = [];
        this.getBuckets();

        this.$toasted.show('Successfully Saved Cost Code Bucket.', {type: 'success', duration: '3000'})
      })
      .catch((error) => {
        console.log(error)
      })
    },
    get_projects(){
      if(this.$session.get('user') == 'pm'){
        this.$http.get('/wts/get/projects')
        .then(response => {
          this.projectData = response.data.result;
          this.companyid = this.projectData[0].companyid
        })
        .catch(error => {
        })
      }
      if(this.$session.get('user') == 'manager' || this.$session.get('user') == 'accountant'){
        this.$http.get('/manager/get/projects')
        .then(response => {
          this.projectData = response.data.result;
          this.companyid = this.projectData[0].companyid
        })
        .catch(error => {
        })
      }

      this.$http.get('/cc_budget/get/')
      .then(response => {
        this.budgets = response.data.result;
      })
      .catch(error => {
      })
    },
    get_afes(){
      this.afes = [];
      this.afeData = [];
      this.chainData = [];

      if(this.$session.get('user') == 'pm'){
        for (var i = 0; i < this.projectids.length; i++) {
          this.$axios.get('/wts/get/afes/' + this.projectids[i].projectid).then(response => {
            this.afeData = this.afeData.concat(response.data.result)
          })
          this.$axios.get('/wts/get/assign_services/' + this.projectids[i].projectid).then(response => {
            this.chainData = this.chainData.concat(response.data.result)
          })
        }
      }
      if(this.$session.get('user') == 'manager' || this.$session.get('user') == 'accountant'){
        for (var i = 0; i < this.projectids.length; i++) {
          this.$axios.get('/manager/get/wts_afe/' + this.projectids[i].projectid + "/" + this.companyid).then(response => {
            this.afeData = this.afeData.concat(response.data.result)
          })
          this.$axios.get('/wts/get/assign_services/' + this.projectids[i].projectid).then(response => {
            this.chainData = this.chainData.concat(response.data.result)
          })
        }
      }
    },
    get_chains(){


    },
    async loadChart(bucket){
      this.loading = true;
      const that = this;

      var chart = am4core.create("chartdiv", am4charts.XYChart3D);
      chart.colors.list = [
        am4core.color("#4dbd74"),
        am4core.color("#c0504e"),
        am4core.color("#444444"),
      ];

      var chartData = [];

      for (var i = 0; i < bucket.cc_group.length; i++) {
        chartData.push({
          "bucket": bucket.cc_group[i].name,
          "cost": this.formatPrice(bucket.cc_group[i].totalCost),
          "budget": this.formatPrice(bucket.cc_group[i].totalBudget),
          "difference": this.formatPrice(bucket.cc_group[i].totalCost - bucket.cc_group[i].totalBudget),
        })
      }

      chart.legend = new am4charts.Legend();

      am4core.ready(function() {
        chart.data = chartData;

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "bucket";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 0;

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Dollars";
        valueAxis.min = 0;
        valueAxis.renderer.labels.template.adapter.add("text", function(text) {
          return "$" + text;
        });

        var series2 = chart.series.push(new am4charts.ColumnSeries());
        series2.dataFields.valueY = "budget";
        series2.dataFields.categoryX = "bucket";
        series2.name = "Total Budget";
        series2.clustered = true;
        series2.columns.template.tooltipText = "{category} Total Budget: [bold]{budget}[/] - Total Cost: [bold]{cost}[/]  = [bold]{difference}[/]";
        series2.tooltip.autoTextColor = false;
        series2.tooltip.label.fill = am4core.color("#FFFFFF");

        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "cost";
        series.dataFields.categoryX = "bucket";
        series.name = "Total Cost";
        series.clustered = true;
        series.columns.template.tooltipText = "{category} Total Budget: [bold]{budget}[/] - Total Cost: [bold]{cost}[/]  = [bold]{difference}[/]";
        series.columns.template.fillOpacity = 0.9;
        series.tooltip.autoTextColor = false;
        series.tooltip.label.fill = am4core.color("#FFFFFF");

        var series3 = chart.series.push(new am4charts.ColumnSeries());
        series3.dataFields.valueY = "difference";
        series3.dataFields.categoryX = "bucket";
        series3.name = "Difference";
        series3.clustered = true;
        series3.columns.template.tooltipText = "{category} Total Budget: [bold]{budget}[/] - Total Cost: [bold]{cost}[/]  = [bold]{difference}[/]";
        series3.tooltip.autoTextColor = false;
        series3.tooltip.label.fill = am4core.color("#FFFFFF");


        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "Cost Bucket Chart Export";
        chart.exporting.menu.items = [{
          "label": "Export <i class='fa fa-export'></i>",
          "menu": [
            {
              "label": "Image",
              "menu": [
                { "type": "png", "label": "PNG" },
                { "type": "jpg", "label": "JPG" },
                { "type": "gif", "label": "GIF" },
                { "type": "svg", "label": "SVG" },
              ]
            }, {
              "label": "Data",
              "menu": [
                { "type": "json", "label": "JSON" },
                { "type": "csv", "label": "CSV" },
                { "type": "xlsx", "label": "XLSX" }
              ]
            }, {
              "label": "Print", "type": "print"
            }
          ]
        }];


      }); // end am4core.ready()
      this.loading = false;
    },

  },
  mounted() {
    this.get_projects();
    this.getBuckets();
  }
}
</script>

<style lang="scss">
#bucketChart {
  position: relative;
  .amcharts-amexport-menu-level-0.amcharts-amexport-top {
    top: -50px;
    bottom: auto;
    right: 15px;
  }
  .amcharts-amexport-item.amcharts-amexport-item-level-0 {
    width: 100px;
    height: inherit;
    padding: 0.5em;
  }
  .amcharts-amexport-item {
    border: none;
    background: #4dbd74;

    a {
      color: #fff;
    }
  }
}
#chain-data.bucket-table .VueTables__child-row .chain-data-child-table thead th {
  width: 25% !important;
}
  .bucket-table .chain-data-child-table {
    .table-responsive {
      padding-left: 5% !important;

      thead {
        display: table-header-group !important;
      }
    }
  }
  .bucket-table td:first-child {
    width: 5% !important;
  }
  .bucket-table .VueTables__child-row > td{
    padding: 0 !important;
  }
  .danger {
    color: #db1616;
  }

  .green {
    color: #4dbd74;
  }
  table.bucket-table td,
  table.bucket-table tr, {
    vertical-align: middle;
  }
  .well-datepicker {
    input {
      width: 100%;
      font-size: 100%;
      padding: 0.35rem;
    }
  }
  .rt-multi {
    width: 100% !important;
    .checkboxLayer {
      margin-top: 10px;
      width: 100%;
    }
    .btn-select {
      width: 100% !important;
      display: block !important;
      padding: 6px 40px !important;
    }
  }
  .disabled-dp {
    input {
      background: #e6e6e6;
    }
  }
  #dataTable.wellTable {
    table th:nth-child(3),
    table tr:nth-child(3){
      width: 110px;
    }
  }
  .dropdown-chev {
    position: absolute;
    top: 11px;
    right: 15px;
  }
  .vue-simple-suggest.designed .input-wrapper input {
    padding: 7px;
  }

  .afe-status-table td{
    width: 120px !important;
  }
  #loading-gif {
    z-index: 9999;
    position: absolute;
    top: 30%;
    right: 50%;
  }

  #chain-data {
    //  background-color:greenyellow !important;
    .VueTables__search-field {
      margin-top: 0.5em;
      label {
        display: none;
      }
    }

    @media screen and (min-width: 1200px){
      .table-responsive {
        overflow: hidden !important;
      }
    }

    div table thead tr th {
      background-color: #444;
      color: #fff;
      font-size: 1.2em;
    }
    td {
      vertical-align: middle;
    }
    div table thead tr th:first-child {
      //width: 5%;
    }
    .chain-data-child-table{
        tbody {
          //background-color: rgba(63, 233, 38, 0.09);
        }

        .VuePagination {
          margin-top: 2em;
        }

        th {
          background: #7d7d7d !important;
          color: #fff !important;
          font-size: 1em !important;
        }
        th {
          width: inherit !important;
        }
        th:first-child {
          //width: 5% !important;
        }

        .VueTables__child-row > td {
          padding: 0;
      }
    }
    .parent th {
      background: #444;
      color: #fff;
    }

    .checkslots {
      text-align: center;
      margin: 0 auto;
      display: block;
      margin-top: 2px;
      font-size: 4em;
    }
    .table td {
      padding: 0.55rem;
    }
    .VueTables__heading {
      text-align: left;
    }
    .VuePagination {
      text-align: center;
      justify-content: center;
    }

    .VueTables__search-field {
      display: flex;
    }
    .VueTables__search-field input {
      margin-left: 0.25rem;
    }

    .VueTables__limit-field {
      display: flex;
    }

    .VueTables__limit-field select {
      margin-left: 0.25rem !important;
    }

    .VueTables__table th {
      text-align: left;
    }

    .VueTables__child-row-toggler {
      width: 16px;
      height: 16px;
      line-height: 18px;
      background: #4dbd74;
      display: block !important;
      margin: auto;
      text-align: center;
      padding: 6px !important;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      line-height: 20px;
      color: #fff;
      font-weight: bold;
    }

    .VueTables__child-row-toggler--open {
      line-height: 17px !important;
    }

    .VueTables__child-row-toggler--closed::before {
      content: "\f054";
      font-weight: 400 !important;
      font-family: "Font Awesome 5 Pro" !important;
    }

  .VueTables__child-row-toggler--open::before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 400 !important;
      content: "\f078" !important;
    }
  }

#chain-data{
  .VueTables__child-row-toggler{
    
    display: block !important;
  }
}

</style>
